import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { RequestsService } from 'src/app/api/requests.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  isLoggedIn: boolean = true;
  ishideMenu: boolean;
  public currency: any = "Rs";
  // Update & Error Variable
  public updateCategory: any = { id: "", category_name: "", description: "", created_at: "", updated_at: "", msg: "" };
  public errorCategory: any = { id: "", category_name: "", description: "", created_at: "", updated_at: "", msg: "" };

  public updateStock: any = { id: "", buying_price: "", buyed_price: "", return_quantity: "", company_name: "", supplier_id_fk: "", new_quantity: "", reason: "", product_code: "", product_count: "", renting_price: "", selling_price: "", selling_price_other: "", status: "", product_id_fk: "", stock_quantity: "", name: "", invoice_item_id_fk: "", type: "", barcode: "", date: null, maintain: "", maintain_price: null, day_period: null, mesurement_apply: "", mesurement_type_1: "", mesurement_type_1_value: "", mesurement_type_2: "", mesurement_type_2_value: "",branch_name:""}
  public errorStock: any  = { id: "", buying_price: "", buyed_price: "", return_quantity: "", company_name: "", supplier_id_fk: "", new_quantity: "", reason: "", product_code: "", product_count: "", renting_price: "", selling_price: "", selling_price_other: "", status: "", product_id_fk: "", stock_quantity: "", name: "", invoice_item_id_fk: "", type: "", barcode: "", date: null, maintain: "", maintain_price: null, day_period: null, mesurement_apply: "", mesurement_type_1: "", mesurement_type_1_value: "", mesurement_type_2: "", mesurement_type_2_value: "",branch_name:""}
  public updateProduct: any = { id: "", image: "", product_code: "", type: "", product_name: "", warranty: "", extra_cost_per_day: "", day_period: "", month: "", year: "", description: "", buying_price: "", selling_price: "", status: "", category_name: "", category_id_fk: "", name: "", invoice_item_id_fk: "", warranty_apply: false, expire_apply: false, mesurement_apply: false, mesurement_type: "", mesurement_type_1: "", mesurement_type_1_value: "", mesurement_type_2: "", mesurement_type_2_value: "", deposit: "", is_deposit: false }
  public errorProduct: any = { id: "", image: "", product_code: "", type: "", product_name: "", warranty: "", extra_cost_per_day: "", day_period: "", month: "", year: "", description: "", buying_price: "", selling_price: "", status: "", category_name: "", category_id_fk: "", name: "", invoice_item_id_fk: "", mesurement_types_type_1: "", mesurement_types_type_1_value: "", mesurement_types_type_2: "", mesurement_types_type_2_value: "", deposit: "", is_deposit: false }
  public updateCustomer: any = { id: "", address: "", created_at: "", gender: "", mobile: "", name: "", nic: "", updated_at: "" }
  public errorCustomer: any = { id: "", address: "", created_at: "", gender: "", mobile: "", name: "", nic: "", updated_at: "" }
  public updateSupplier: any = { id: "", address: "", created_at: "", mobile: "", name: "", email: "", updated_at: "", company_name: "" }
  public errorSupplier: any = { id: "", address: "", created_at: "", mobile: "", name: "", email: "", updated_at: "", company_name: "" }
  public updateInvoice: any = { id: "", sid: "", buying_price: "", payment_type: "", cheque_date: "", cheque_no: "", should_return: "", return_date: "", sdiscount: "", sdiscount_type: "", category_id_fk: "", warranty: "", created_at: "", invoice_no: "", description: "", image: "", invoice_item_id_fk: "", product_code: "", product_name: "", selling_price: "", bp: "", sp: "", stock_quantity: "", status: "", type: "", updated_at: "", discount_price: "", ranting_period_end_date: "", ranting_period_start_date: "", received_date: "", dateRange: "", subtolat: "", discount: "", discount_priceAll: "", discount_type: "%", page: "", index: "", discountAll: "", discount_typeAll: "%", day_period: "", discount_error: "", deposit: "", is_deposit: false }
  public errorInvoice: any = { id: "", sid: "", buying_price: "", payment_type: "", cheque_date: "", cheque_no: "", should_return: "", return_date: "", sdiscount: "", sdiscount_type: "", category_id_fk: "", warranty: "", created_at: "", invoice_no: "", description: "", image: "", invoice_item_id_fk: "", product_code: "", product_name: "", selling_price: "", bp: "", sp: "", stock_quantity: "", status: "", type: "", updated_at: "", discount_price: "", ranting_period_end_date: "", ranting_period_start_date: "", received_date: "", dateRange: "", subtolat: "", discount: "", discount_priceAll: "", discount_type: "%", page: "", index: "", discountAll: "", discount_typeAll: "%", day_period: "", discount_error: "", deposit: "", is_deposit: false, stock_not_have: [] }
  public updateMaintain: any;
  public UpdateMassage: any;
  public rows: any;
  public rows_maintaine: any;
  public invoiceData: any = [];
  public InvoiceAllData: any = [];
  public InvoiceTot: any = { search: "", deposit_all: null, tot_sum: null, discountAll: null, tot_all: null, toterrorval: null, discount_typeAll: "-", discount_priceAll: 0 };
  public url: any;
  public invoiceViewID: any;
  public barcodeViewID: any;
  public invoiceStatus: any;
  public productData: any = [];
  public oneProductData: any = { product_total_price: null, quantity: null, dateRange: null, ranting_period_end_date: null, ranting_period_start_date: null, Currency_symble: this.currency, discount_type: "-", discount: 0, discount_price: 0, }
  public invoiceDataUpdate: any = [];
  public updateUser: any = { id: "", email: "", password: "", permission: {}, name: "", status: "", branch_id_fk: "" };
  public errorUser: any = { id: "", email: "", password: "", permission: {}, name: "", status: "", branch_id_fk: "" };
  public select_customer: any = { id: "", name: "" };
  public updateBranch: any = { id: "", branch_name: "", mobile: "", address: "" };
  public errorBranch: any = { id: "", branch_name: "", mobile: "", address: "" };
  public dta = [];
  public user_dta = [];
  public selecter = false;
  public branch_permission = false;
  public branch_id = [];
  public account: any;
  public renttime_over_list: any;
  public updateAppointment: any = { id: "", date: "", time: "", note: "", invoice_id_fk: "", customer_id_fk: "" };
  public errorAppointment: any = { id: "", date: "", time: "", note: "", invoice_id_fk: "", customer_id_fk: "" };
  public cusid = [];
  public updateRent: any = { id: "", barcode: "", customer: "", day_quantity: "", invoice_id_fk: "", invoice_no: "", is_received: "", product_price: "", extra_cost_per_day: "", product_total_price: "", quantity: "", ranting_period_end_date: "", ranting_period_start_date: "", stock_id_fk: "", product_code: "", balance: "", deposit: "", is_deposit: false, ideposit: "", iis_deposit: false };

  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    private storage: Storage,
    private apiService: RequestsService
  ) { }

  // Success Alert
  async success(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'top',
      cssClass: 'toastcss',
      duration: 2000,
      color: 'success',
      buttons: [
        {
          side: 'end',
          icon: 'checkmark',
          text: 'Done'
        }
      ]
    });
    toast.present();
  }

  // Fail Allert
  async fail(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'top',
      cssClass: 'toastcss',
      duration: 2000,
      color: 'danger',
      buttons: [
        {
          side: 'end',
          icon: 'close',
          text: 'Fail'
        }
      ]
    });
    toast.present();
  }

  branch_list() {
    this.storage.get('token').then(token => {
      if (token != null && token != '') {
        this.apiService.post('branch_list', { token: token }).then(data => {
          this.dta = data;
          this.storage.get('permission').then(permission => {
            if (permission.branch != null) {
              this.branch_permission = permission.branch;
            }
            if (permission.branch && data.length == 1) {
              this.selecter = false;
            } else {
              this.selecter = true;
            }
          });
        });
      }
    });
  }

  user_list() {
    return new Promise((resolve, reject) => {
      this.storage.get('token').then(token => {
        if (token != null && token != '') {
          this.apiService.post('get_user_info', { token: token }).then(data => {
            this.user_dta = data.success.data;
            return resolve(data.success.data);
          }).catch((error) => {
            return reject(error);
          });
        }
      });
    });
  }

  get_branch() {
    this.storage.get('branch').then(data => {
      this.branch_id = data;
    })
  }

  get_curren() {
    this.storage.get('branch').then((data) => {
      console.log(data)
    });
  }

  setcurrency() {
    this.storage.get('token').then(token => {
      if (token != null && token != '') {
        this.apiService.post('setting/get', { token: token }).then(data => {
          this.currency = data.success.data.currency;
        });
      }
    });
  }
}